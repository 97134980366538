$accentColour: #5b8cf1 !default;
$linkColour: #5b8cf1 !default;
$hoverColour: #3f6ecc !default;

@import "alembic";

.article img {
  margin: 0 auto;
  display: block;
}

.feature {
  background-position-y: 65%;
}

.feature .container {
  min-height: 60vh;
}

// fixed header
// .header {
//  position: fixed;
//  width: 100%;
//}

// body {
//   overflow: hidden;
// }

// main {
//   flex: 1 1 auto;
//   overflow-y: auto;
//   margin-bottom: 0;
// }

// footer {
//   display: none;
// }
